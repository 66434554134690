.custom-datalist {
  /* Add your custom styles here */
}

.custom-datalist input {
  /* Add styles for the input field within the custom datalist */
}

.custom-datalist datalist {
  background-color: red;
}