@import '../../../styles/mixin';
@import '../../../styles/variables';

.form-new-user-wrap {
  position: relative;
  width: calc(100vw - $sidebar-width - 2rem);
  min-height: auto;
  height: auto;
  @include flexbox-center;
  justify-content: flex-start;
  @include flex-direction-column;

  &.collapse-menu {
    width: calc(100vw - $sidebar-width + 8rem);
  }

  .form-inner-wrap {
    width: 70%;
    padding-top: 0;
    margin: 7rem 0 1rem;
  }

  form {
    padding-top: 2.5rem;
  }

  .input-wrap {
    &.error-input {
      input {
        border-color: $error;
      }
    }
  }

  .form-submit.blue-btn {
    width: 17.625rem;
  }
}

.form-wrap form .input-wrap .input-block input {
  width: 100%;
}

.new-user-loading {
  @include flexbox-center;
  width: 100%;

  .loader {
    margin: 0;
  }
}

.new-user-error,
.new-user-success {
  width: fit-content;
  margin: 1rem auto 0;
  height: auto;
  @include font-bold;
  padding: 0.45em 0.775em;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: $btn-border-radius;
}

.new-user-success {
  background: #bce2be;
  color: #339537;
}

.new-user-error {
  background: #e2bcbf;
  color: $error;
}
