@import "../../styles/mixin";
@import "../../styles/variables";

.yieldkit-offers-list-btns-container {
  position: relative;
  @include flexbox-start-center;
  width: 100%;
  max-width: calc(100vw - $sidebar-width - 3rem);

  &.collapse-menu {
    max-width: calc(100vw - 9.5rem);
  }

  &.disabled {
    .custom-select-option {
      color: #666666 !important;
      &:hover {
          background-color: $disabled-bg !important;
      }

      &.active {
        border: 1px solid #666666 !important;
        color: #666666 !important;
      }
    }
  }
}

.yieldkit-offers-list-acc-select {
  margin-left: auto;

  .category-dropdown.custom-select-horizontal {
    @include flexbox-center;
    padding: 0;
    min-height: auto;
    box-shadow: none !important;

    ul.custom-select-horizontal {
      background: $table-bg;
      border: 1px solid $tabs-bg;
      border-radius: .625rem;

      li {
        @include flexbox-center;
        width: 100%;
        padding: .5rem .75rem;
        @include font-regular;
        font-size: .875rem;
        line-height: 1rem;
        text-transform: none;
        color: $sidebar-bg;
        border: 1px solid transparent;
      }
    }
  }

  .custom-select-option-container.custom-select-horizontal {
    position: relative;
    @include flexbox-center;
    align-items: stretch;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .custom-select-option {
    padding: 0 .5rem;

    &.active {
      background-color: $main-bg;
      border: 1px solid $btn-bg !important;
      color: $btn-bg !important;
    }

    &:hover {
      border: 1px solid $tabs-bg;
    }

    &:first-child.active,
    &:first-child:hover {
      border-radius: 0;
      border-bottom-left-radius: 0.625rem;
      border-top-left-radius: 0.625rem;
    }

    &:last-child.active,
    &:last-child:hover {
      border-radius: 0;
      border-bottom-right-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
    }

    &.custom-select-vertical {
      padding: 0.5rem;

      &.active,
      &:hover {
        border: none;
        background-color: #F8F9F9;
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
      }
    }
  }
}

.yieldkit-accounts-btn {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .blue-btn {
    margin-bottom: 0;
  }

  .yieldkit-accounts-select {
    min-height: 2.5rem;
    min-width: 4rem;
    border-radius: 0.5rem;
    margin-left: 0.75rem;
    margin-right: 0;
  }

  .sending-data-loading {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .doubled-checkbox-block {
    margin: 0 0 0 calc(.75rem - 4px);
    &.disabled {
      input {
        background-color: $disabled-bg !important;
        &:before {
          color: #666666;
        }
      }
    }
    input:checked {
      background-color: $btn-bg;
    }
  }
}

.pre-yieldkit-offers-table-wrap {
  position: relative;

  .pagination-loading {
    position: absolute;
    height: 60px;
    left: 0;
    right: 15px;
    bottom: -15px;
    padding-right: 1rem;
    background-color:#F8F9F9;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.yieldkit-offers-list-btns-container,
.yieldkit-accounts-btn {
  &.disabled {
    .blue-btn, .clear-filters-btn, .custom-select-option, .custom-select-option.active {
      background-color: $disabled-bg;
      color: #666666;
    }

    .blue-btn, .clear-filters-btn, .custom-select-option.active {
      border: 1px solid #999999;
    }

    .yieldkit-accounts-select, .blue-btn, .clear-filters-btn, .custom-select-option, .custom-select-option.active {
      cursor: not-allowed;
    }

    .custom-select-option.active:hover {
      border: 1px solid #999999;
    }

    .blue-btn:hover, .clear-filters-btn:hover {
      box-shadow: none;
    }

    .custom-select-option:hover {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid $disabled-bg;
    }
  }
}

.customCheckbox input {
  width: 3rem;
  height: 1rem;
}
.customCheckbox input::after {
  height: 0.65rem;
  width: 0.65rem;
}
.fileDescription {
  font-size: 12px;
}
