@import "../../../styles/variables";
@import "../../../styles/mixin";

.ag-theme-alpine {
  &.small-table {
    .ag-root {
      min-height: auto;
      max-height: none;
    }
  }

  h3 {
    margin: 1rem 1rem;
  }

  .ag-row .ag-cell:last-child {
    padding-right: 2rem;
  }
}

.ag-root-wrapper {
  height: min-content !important;
  background-color: $popup-bg;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: $btn-border-radius;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  height: .5rem;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  background: #d6d6d6;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background: #696970;
  border-radius: $btn-border-radius;
  box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.closed-campaigns-container .ag-body {
  overflow-y: scroll;
}

.ag-header {
  background-color: transparent;

  .ag-pinned-right-header {
    padding: 0 2.19rem;
  }
}

.ag-horizontal-left-spacer {
  display: none;
}

.ag-horizontal-right-spacer {
  width: 0 !important;
  min-width: 8px !important;
}

.ag-pinned-right-cols-container {
  overflow: hidden !important;
}

.ag-header-cell {
  display: block;
  @include font-bold;
  margin: 0 .5rem;
  opacity: 0.8;
  color: $main-font;
  font-size: 0.65rem;
  text-transform: uppercase;
}

.col-names-break .ag-header-cell-text {
  overflow: auto;
  white-space: normal;
}

.ag-cell {
  @include flexbox-start-center ;
  @include font-regular;
  padding: 0.5rem 0.2rem 0.5rem 1rem;
  color: $main-font;
  font-size: 0.9rem;
  line-height: 1.3;
  cursor: pointer;
}

.yieldkit-accounts-page-container {
  .ag-cell {
    @include flexbox-start;
  }
}

.ag-cell-wrap-text {
  word-break: break-word;
}

.ag-paging-panel {
  position: absolute;
  bottom: -0.25rem;
  right: 0;
  z-index: 2;
  border-top: 0;
}

.ag-paging-panel > * {
  color: $main-font;
}

.ag-root-wrapper-body {
  height: min-content !important;
  min-height: 14rem;
  flex: initial !important;
  border-bottom: 3rem solid $main-bg;
}

.ag-root {
  height: min-content;
  min-height: 14rem;
  max-height: calc(100vh - 17rem);
  padding-bottom: 1rem;
  box-shadow: $box-shadow;
  border-bottom-right-radius: $btn-border-radius;
  border-bottom-left-radius: $btn-border-radius;
}

.shorter-table-wrap-with-tag {
  .ag-root {
    max-height: calc(100vh - 20rem);
  }
}

.ag-body {
  height: min-content;
  overflow: auto;
  padding: 0 0.5rem;

  &::-webkit-scrollbar {
    width: .5rem;
  }

  &::-webkit-scrollbar-track {
    background: #d6d6d6;
  }

  &::-webkit-scrollbar-thumb {
    background: #696970;
    border-radius: $btn-border-radius;
    box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  }

  &.no-data-title:after {
    content: 'No data for this filter';
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: $main-font;
  }
}

.ag-body-clipper {
  height: min-content;
}

.ag-body-viewport {
  height: min-content !important;
}

.ag-center-cols-clipper {
  height: min-content;
}

.table-inner-container {
  position: relative;
}

.pagination-select {
  position: absolute;
  bottom: 0;
  left: 1rem;
  @include flexbox-center;
  z-index: 4;

  .category-dropdown {
    position: relative;
    min-height: auto;
    margin-bottom: 0;
    padding: .5rem 2rem .5rem .5rem;
    background-color: $main-bg;
    border: 1px solid #3E79F7;
    border-radius: 10px;

    &:after {
      content: '';
      position: absolute;
      top: .8rem;
      right: .65rem;
      display: block;
      width: .75rem;
      height: .5rem;
      margin-left: .25rem;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.08834 6L0 0.911661L0.911661 0L5.08834 4.19788L9.26502 0.0212014L10.1767 0.932862L5.08834 6Z' fill='%238F9EB0' fill-opacity='0.5'/%3E%3C/svg%3E");
      @include bg-contain;
    }

    &.active {
      border: 0 solid transparent;
      border-right: none;
      border-radius: $btn-border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .custom-select-option-container {
    top: calc(2rem + 3%);
    left: 0;
    @include flexbox-start;
    @include flex-direction-column;
    border-radius: 0;
    border-bottom-right-radius: $btn-border-radius;
    border-bottom-left-radius: $btn-border-radius;
    width: 100%;
    background-color: $main-bg;
  }

  .custom-select-option {
    padding: .25rem .5rem;
    text-transform: none;
    @include font-regular;
    font-size: .875rem;
    line-height: 1rem;
    color: $sidebar-bg;

    &:hover {
      background-color: $tabs-bg;
    }

    &:last-child:hover {
      background-color: $tabs-bg;
      border-radius: 0;
      border-bottom-left-radius: $btn-border-radius;
      border-bottom-right-radius: $btn-border-radius;
    }
  }

  .selected-text {
    text-transform: none;
    @include font-regular;
    font-size: .875rem;
    line-height: 1rem;
    color: $sidebar-bg;
  }
}

.ag-icon-first::before,
.ag-icon-previous::before,
.ag-icon-next::before,
.ag-icon-last::before {
  color: #72849A;
}

.btns-container {
  @include flexbox-center;
  @include flex-direction-column;
}

.voluum-config-table .ag-cell {
  white-space: break-spaces;
}

.filter-tags-block {
  @include flexbox-start-center;
}

.filter-tags-container {
  @include flexbox-start;
  margin-bottom: 1rem;

  li {
    display: table;
    padding: .2rem .5rem;
    margin-right: .2rem;
    background: rgba(62, 121, 247, 0.15);
    border: 1px solid $btn-bg;
    border-radius: $btn-border-radius;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .filter-tags-active {
    border: 1px solid #FF6B72;
    background: rgba(255, 107, 114, 0.15);
  }
}

.filter-tags-active-container {
  width: auto;
}

.no-data-title {
  font-size: 1.25rem;
  color: $sidebar-bg;
}

// FILTER MENU
.ag-menu {
  padding: 0;
  box-shadow: $box-shadow;
  border: none;
  border-radius: $btn-border-radius;
}

.ag-simple-filter-body-wrapper {
  padding: .875rem .625rem;
  background: $table-bg;
}

.ag-select:not(.ag-cell-editor) {
  height: initial;
}

.ag-filter-select .ag-picker-field-wrapper {
  width: 100%;
}

.ag-theme-alpine .ag-ltr input[class^=ag-],
.ag-select .ag-picker-field-wrapper {
  padding: 8px 16px 8px 20px;
  background: $table-bg;
  border: 1px solid $tabs-bg;
  border-radius: $btn-border-radius;
  @include font-regular;
  font-size: .875rem;
  line-height: 1rem;
  color: $main-font;
}

.ag-icon-small-down::before {
  content: '';
  display: block;
  width: .8rem;
  height: .5rem;
  margin-left: .25rem;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 0.881958L5.5 4.88196L1.5 0.881958' stroke='%238F9EB0' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
  @include bg-contain;
}

.ag-icon-filter::before {
color: $btn-bg;
}

.ag-cell-value[id^=cell-path_status_in_the_flow-],
.ag-cell-value[id^=cell-path_status-],
.ag-cell-value[id^=cell-offer_status-],
.ag-cell-value[id^=cell-campaign_status_in_traffic_source-],
.ag-cell-value[id^=cell-cap_action-],
.ag-cell-value[id^=cell-status-],
.ag-cell-value[id^=cell-current_status-],
.ag-cell-value[id^=cell-active-],
.ag-cell-value[id^=cell-link_process_status-] {
  overflow: initial;
}

.ag-cell[aria-describedby^=cell-process_status-] {
  justify-content: center;
  padding-right: 2rem;
}

.yk-offer-progress.table-loader {
  position: relative;
  top: 0;
  right: 0;
}

.yk-offer-success {
  color: #04D182;
}

.yk-offer-fail {
  color: $fail;
}

.status-cell {
  display: block;
  padding: .25rem .75rem;
  margin-right: 1rem;
  border-radius: $btn-border-radius;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1rem;
  text-transform: capitalize;

  &.empty-cell {
    padding: 0;
  }

  &.active-cell {
    background: rgba(4, 209, 130, 0.2);
    color: #04D182;
  }

  &.deactivated-cell {
    background: rgba(255, 107, 114, 0.2);
    color: $fail;
  }

  &.not-found-cell {
    background: $tabs-bg;
    color: $sidebar-bg;
  }
}

// PAGINATION


