@import "../../styles/variables";

.react-datepicker-wrapper {
  width: initial;
}
.react-datepicker__input-container {
  input {
    max-width: 8rem;
    margin: 0 .5rem 1rem auto;
    padding: .8rem .5rem;
    text-align: center;
    border: none;
    border-radius: $btn-border-radius;
    color: $main-font;
    transition: all 150ms ease-in 0s;
    cursor: pointer;

    &:hover {
      box-shadow: $btn-box-shadow;
    }
  }
}

.react-datepicker-popper {
  z-index: 10;

  &[data-placement^=bottom] .react-datepicker__triangle {
    display: none;
  }
}
