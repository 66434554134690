@import "../../../styles/mixin";
@import "../../../styles/variables";

.dt-create-campaign-popup-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexbox-center;
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;

  form {
    width: 100%;
  }

  .form-input {
    height: 30px;
    font-size: 12px;
    color: #333333;
    border: 1px solid #cccccc;
    padding: 0 8px;
    border-radius: 4px;
    margin: 10px 10px 0 0;
    width: 100%;

    &:hover {
      border-color: #999999;
    }
  }

  .form-select {
    margin: 10px 0 0;
  }

  .form-field-error {
    font-size: 12px;
    text-align: right;
    color: #c50000;
  }
}

.dt-create-campaign-popup-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 450px;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 8px;

  h4 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    line-height: 1.2;
  }

  .config-btns-block {
    position: relative;
    bottom: 0;
    margin: 20px 0 0;
  }
}

.add-item-submit-block {
  position: relative;
  width: 100%;
}