@import "../../../styles/mixin";
@import "../../../styles/variables";

.otp-popup {
  position: absolute;
  top: 80px;
  right: -17px;
  bottom: 0;
  left: -17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(48, 54, 75, 0.5) 0%, rgba(36, 41, 57, 0.5) 100%);
    box-shadow: 0 4px 24px rgba(150, 150, 178, 0.1);
    z-index: 2;
  }

  .content-wrap {
    padding: 48px 42px;
    max-width: 672px;
    width: 100%;
    min-height: 250px;
    max-height: 90vh;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 0 24px rgba(150, 150, 178, 0.1);
    border-radius: 16px;
    overflow-y: auto;
    z-index: 2;

    &::-webkit-scrollbar {
      width: .5rem;
    }

    &::-webkit-scrollbar-track {
      background: #d6d6d6;
      border-radius: $btn-border-radius 5rem 5rem $btn-border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background: #69697087;
      border-radius: $btn-border-radius;
      box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    }

    h3 {
      margin-bottom: 48px;
      text-align: center;
    }
  }

  h3, h4 {
    @include font-bold;
    font-size: 24px;
    line-height: 1.16;
    color: $sidebar-bg;
  }

  h4 {
    margin-bottom: 16px;
  }

  p, li {
    @include font-regular;
    font-size: 14px;
    line-height: 1.14;
    color: $sidebar-bg;
  }

  .configuring-wrap,
  .code-wrap,
  .verify-code-wrap {
    margin-bottom: 40px;
  }

  .qr-code {
    margin-bottom: 25px;

    .qr-code-wrap {
      max-width: 178px;
      width: 100%;
      height: 178px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .verify-code-wrap {
    position: relative;
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 16px;
    }

    input {
      max-width: 300px;
      width: 100%;
      border: 1px solid #467FF7;
      border-radius: 8px;
      padding: 11px 20px;
      @include font-regular;
      font-size: 14px;
      line-height: 1.16;
      color: $sidebar-bg;

      &::placeholder {
        color: #9696B2;
      }

      &.input-error {
        border-color: red;
      }
    }

    .error-text {
      position: absolute;
      bottom: -22px;
      font-size: 12px;
      color: red;
    }
  }

  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;

    button {
      border-radius: 8px;
      padding: 12px 0;
      max-width: 282px;
      width: 100%;
      @include font-medium;
      font-size: 14px;
      line-height: 1.16;
      border: none;
    }

    .close-btn {
      background: #F0F2F5;
      color: $sidebar-bg;
      margin-right: 24px;
    }

    .verify-btn {
      background: #3E79F7;
      color: #fff;
    }
  }
}