@import "../../styles/mixin";
@import "../../styles/variables";

.category-dropdown {
  position: relative;
  min-width: 7rem;

  &.disabled {
    background: $disabled-bg;
    cursor: not-allowed;
    border: 1px solid #999999;

    &:hover {
      background: $disabled-bg;
      box-shadow: none;
    }
  }

  &:hover {
    background: #ffffffad;
  }

  &.active {
    background: $sidebar-font;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: $btn-box-shadow !important;
  }
}
.custom-select-option-container {
  position: absolute;
  left: 0;
  top: calc(2.5rem + 1%);
  width: 100%;
  text-align: center;
  background-color: $sidebar-font;
  border: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: $btn-box-shadow;
  z-index: 10;

}
.custom-select-option {
  padding: .5rem .25rem;
  width: 100%;

  &:last-child:hover {
    background: $main-bg;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

