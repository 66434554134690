@import "../../styles/mixin";
@import "../../styles/variables";

.not-found-container {
  @include flexbox-center;
  width: calc(100vw - $sidebar-width - 2rem);
  height: calc(100vh - 9rem);
}

.not-found-title {
  color: $sidebar-bg;
  font-size: 15rem;
}

.not-found-img-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  @include flexbox-end;
  overflow: hidden;
  z-index: -1;

  .not-found-img-main {
    width: 20rem;
    height: auto;
    margin: 0 -3rem -3rem 0;
  }
}
