.navigate-to-btn {
  margin-top: 1rem;

  span {
    margin-right: .5rem;
  }

  svg {
    height: .75rem;
  }
}

.page-btns-container .navigate-to-btn {
  margin: 0 .5rem 1rem 0;
  padding: 0.625rem .875rem;

  span {
    margin-right: 0;
    margin-left: .25rem;
  }

  svg {
    transform: rotate(180deg);
  }
}
