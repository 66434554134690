@import "../../styles/variables";
@import "../../styles/mixin";

.affiliate-network-offers-container {
  .filter-tags {
    font-size: 13px;
    padding: 2px 8px;
  }

  .blue-btn {
    margin: 0;
  }
}

.data-table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;

  .pagination-wrap {
    margin: 0 0 0 15px;
  }
}

.data-table-rows-limit {
  max-width: 150px;
  width: 100%;
}

.resizer-handle {
  background: #dddddd;
}

.data-table-rows-count {
  font-size: 14px;
  margin: 0 0 0 auto;
}