@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@mixin flexbox-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flexbox-start-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flexbox-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin flexbox-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: center;
}

@mixin flexbox-space-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: space-between;
}

@mixin flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-direction-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin flex-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-direction-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@mixin flex-wrap($val) {
  -moz-box-wrap: $val;
  -webkit-box-wrap: $val;
  -ms-flexbox-wrap: $val;
  flex-wrap: $val;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin bg-contain {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@mixin grid-template($column-start, $column-end, $row-start, $row-end) {
  grid-column-start: $column-start;
  grid-column-end: $column-end;
  grid-row-start: $row-start;
  grid-row-end: $row-end;
}

@mixin grid-template-auto {
  grid-column-start: auto;
  grid-column-end: auto;
  grid-row-start: auto;
  grid-row-end: auto;
}

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin font-bold {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  letter-spacing: 0.0075em;
  font-style: normal;
  font-display: swap;
}

@mixin font-medium {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@mixin font-regular {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@mixin font-thin {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
