@import '../../styles/mixin';
@import '../../styles/variables';

.yk-checker-btns-container {
  @include flexbox-end;
  margin-top: -1rem;
  padding-right: 1rem;

  .transparent-btn {
    margin-left: .5rem;
  }

  .all-results {
    margin-right: .5rem;
  }
}

.yk-checker-countdown {
  margin-top: -.5rem;
  padding-bottom: .5rem;
  font-size: .85rem;
  opacity: .7;
}

.yk-checker-text {
  font-size: 1.1rem;
  color: $sidebar-bg;
}

.yk-checker-hint {
  font-size: .875rem;
  line-height: .5rem;
}

.yk-checker-form-error {
  display: block;
  margin-top: -.5rem;
  font-size: 1rem;
  color: $error;
}