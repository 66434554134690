@import "../../styles/variables";
@import "../../styles/mixin";

.admin-links-list {
  .all-links-item {
    margin-top: 1rem;
    p {
      margin-right: .5rem;
      font-size: 1rem;
    }
  }

  li {
    @include flexbox-start-center;

    a {
      color: $main-font;
      transition: $transition;
      &:hover {
        color: $btn-bg;
        text-decoration: underline;
      }
    }

    .link-icon {
      @include flexbox-center;
      margin-right: .5em;
      cursor: pointer;

      .MuiSvgIcon-root {
        transition: $transition;
      }
      &:hover {
        .MuiSvgIcon-root {
          fill: $btn-bg !important;
        }
      }
    }
  }
}

