@import "../../../styles/mixin";
@import "../../../styles/variables";

.reset-pass-popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(48, 54, 75, 0.5) 0%, rgba(36, 41, 57, 0.5) 100%);
    box-shadow: 0 4px 24px rgba(150, 150, 178, 0.1);
    z-index: 2;
  }

  .content-wrap {
    padding: 48px 42px;
    max-width: 500px;
    width: 100%;
    min-height: 250px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 0 24px rgba(150, 150, 178, 0.1);
    border-radius: 16px;
    z-index: 2;

    h3 {
      margin-bottom: 40px;
      text-align: center;
    }
  }

  h3, h4 {
    @include font-bold;
    font-size: 24px;
    line-height: 1.16;
    color: $sidebar-bg;
  }

  h4 {
    margin-bottom: 16px;
  }

  .inputs-wrap {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 360px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      @include font-medium;
      font-size: 14px;
    }

    p {
      margin-bottom: 16px;
    }

    input {
      max-width: 100%;
      width: 100%;
      border: 1px solid #467FF7;
      border-radius: 8px;
      padding: 11px 20px;
      @include font-regular;
      font-size: 14px;
      line-height: 1.16;
      color: $sidebar-bg;

      &::placeholder {
        color: #9696B2;
      }

      &.input-error {
        border-color: red;
      }
    }

    .error-text {
      position: absolute;
      bottom: -22px;
      font-size: 12px;
      color: red;
    }
  }

  .btn-wrap {
    display: flex;
    flex-direction: row-reverse;

    button {
      border-radius: 8px;
      padding: 12px 0;
      max-width: 282px;
      width: 100%;
      @include font-medium;
      font-size: 14px;
      line-height: 1.16;
      border: none;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .close-btn {
      background: #F0F2F5;
      color: $sidebar-bg;
      margin-right: 24px;
    }

    .verify-btn {
      background: #3E79F7;
      color: #fff;
    }
  }
}