// COLORS
$main-bg: #F8F9F9;
$sidebar-bg: #1A3353;
$header-bg: #FFFFFF;
$table-bg: #FFFFFF;
$popup-bg: #FFFFFF;
$tabs-bg: #F0F2F5;
$main-font: #001529;
$logout-font: #8F9EB0;
$sidebar-font: #FFFFFF;
$sidebar-width: 18rem;
$sidebar-box-shadow: 0px 4px 24px rgba(150, 150, 178, 0.1);
$disabled-bg: #cccccc;
$btn-bg: #467FF7;
$btn-bg-hover: #3065d6;
$active-btn: rgba(62, 121, 247, 0.15);
$black: #000000;
$error: #f44335;
$fail: #ff6b72;

// GENERAL
$transition: all .3s;
$border-radius: 0.75rem;
$btn-border-radius: 0.5rem;
$box-shadow: 0 0 24px rgba(150, 150, 178, 0.1);
$btn-box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
$block-border: 0 solid rgba(0, 0, 0, 0.125);
