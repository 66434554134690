@import "../../../styles/mixin";
@import "../../../styles/variables";

.add-item-popup-block {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  min-width: 32rem;
  height: 100vh;

  h4 {
    margin-bottom: 10px;
  }
}

.add-item-error {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  color: red;
}

