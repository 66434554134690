@import "../../../styles/mixin";
@import "../../../styles/variables";

.dt-change-status-popup-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexbox-center;
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;
}

.dt-change-status-popup-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 400px;
  height: auto;
  max-height: 90vh;
  background-color: #fff;

  h4 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
    line-height: 1.2;
  }
  
  .config-btns-block {
    position: relative;
    bottom: 0;
  }

  .status-select {
    margin: 0 0 20px;
  }

  .update-btn.disabled {
    cursor: not-allowed;
    background-color: $disabled-bg;
  }
}

.add-item-submit-block {
  position: relative;
  width: 100%;
}

