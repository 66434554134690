@import "../../../styles/mixin";
@import "../../../styles/variables";

.add-item-popup-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 16.5rem;
  @include flexbox-center;
  background-color: rgba(0, 0, 0, .5);
  z-index: 11;
}

.add-item-popup-block {
  position: absolute;
  top: 5rem;
  right: 0;
  background-color: $table-bg;

  h4 {
    margin-bottom: 10px;
  }
}

.input-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0 7px;

  label {
    text-transform: capitalize;
  }

  input {
    width: 100%;
  }
}

.add-item-error {
  width: 100%;
  text-align: center;
  color: red;
}


