@import "../../../styles/mixin";
@import "../../../styles/variables";

.alerts-download-select-container.admin-alerts-download,
.csv-relative-pos {
  .csv-btn-wrap {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 -.25rem 0 .5rem;

    .blue-btn {
      margin: 0;
    }
  }
}



