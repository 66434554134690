@import '../../styles/mixin';
@import '../../styles/variables';

.form-flex-row {
  @include flexbox-start-center;
  margin: 0.5rem 0 1rem;
}
.form-flex-col {
  @include flexbox-start-center;
  @include flex-direction-column;
  z-index: 3;

  p {
    align-self: flex-start;
  }
}

.form-wrap {
  height: 100vh;
  overflow-y: auto;

  .form-inner-wrap {
    position: relative;
    padding: 3rem 1.5rem 2rem 1.5rem;
    @include flexbox-center;
    @include flex-direction-column;
    background-color: $popup-bg;
    background-clip: border-box;
    border-radius: $border-radius;
    box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  }

  .title-wrap {
    @include flexbox-space-between;
    width: 100%;
    padding: 1.5rem 2.5rem;
    @include font-bold;
    font-size: 1rem;
    line-height: 1.188rem;
    color: $sidebar-bg;
    border-bottom: 1px solid $tabs-bg;

    .close-btn {
      @include flexbox-center;
      width: 2.375rem;
      height: 2.375rem;
      padding: 0;
      color: #8F9EB0;
      background-color: $fail;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        box-shadow: $btn-box-shadow;
      }
    }
  }

  .form-loading {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include flexbox-center;
    z-index: 5;
  }

  form {
    width: 100%;
    @include flexbox-center;
    @include flex-direction-column;

    .form-flex-container {
      @include flexbox-start;
      width: 100%;
      padding-top: 0.5rem;

      .form-left-block,
      .form-right-block {
        width: 100%;
      }
    }

    .form-flex-container {
      @include flex-direction-column;
      padding: 0.5rem 2.5rem;
    }

    .input-block {
      position: relative;
      margin: 0 auto 1rem;
      overflow: hidden;

      &.input-search-select-block {
        overflow: initial;
      }

      input,
      select,
      textarea {
        width: 70%;
        height: 100%;
        margin-top: .25rem;
        padding: .75rem 1.25rem;
        border: 1px solid $tabs-bg;
        border-radius: $btn-border-radius;
        @include font-regular;
        font-size: .875rem;
        line-height: 1rem;
        color: $logout-font;

        &:focus,
        &:active {
          color: $sidebar-bg;
          border: 1px solid $btn-bg;
          outline: none;
        }
      }

      label,
      legend {
        margin-bottom: .35rem;
        @include font-medium;
        font-size: .875rem;
        line-height: 1rem;
        color: $sidebar-bg;
      }
    }

    .input-wrap {
      position: relative;
      width: 100%;
      margin-bottom: 1rem;
    }

    .error-text {
      margin-top: -1rem;
      @include font-regular;
      font-size: 12px;
      color: $error;
      z-index: 1;
    }

    .inside-input {
      top: 11px;
      left: 4px;
      z-index: 1;
      select {
        padding: 0 3px !important;
        width: 97px !important;
        height: 46px !important;
        font-size: 12px;
      }
    }

    .outside-input {
      margin-left: -110px !important;
      width: 200px !important;
      input {
        padding: 0.75rem 1.25rem 0.75rem 100px !important;
      }
    }
    .form-settings-block {
      @include flexbox-start-center;
      width: 100%;

      button {
        margin: 0 0 0 1rem !important;
      }
    }
  }
}

.custom-form-hint {
  position: absolute;
  width: 250px;
  padding-top: .25rem;
  @include font-thin;
  letter-spacing: 0.00938em;
  font-size: 0.875rem;
  color: rgb(123, 128, 154);
}

.form-hint {
  width: auto;
  padding-top: .25rem;
  @include font-thin;
  letter-spacing: 0.00938em;
  font-size: 0.875rem;
  color: rgb(123, 128, 154);
}

.add-item-popup-block {
  .config-btns-block {
    position: relative;
    bottom: 0;
    margin-bottom: 2rem;
  }
}

.config-btns-block {
  position: absolute;
  bottom: 3rem;
  @include flexbox-space-between;
  width: 100%;
  padding: 0 2.5rem;

  .discard-btn,
  .update-btn {
    display: block;
    width: 47%;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 0.75rem 0;
    @include font-medium;
    font-size: .875rem;
    line-height: 1rem;
    border: none;
    border-radius: $btn-border-radius;
    color: $sidebar-font;
    transition: $transition;
    cursor: pointer;
  }

  .update-btn {
    background-color: $btn-bg;

    &:hover {
      box-shadow: $btn-box-shadow;
    }
  }

  .discard-btn {
    color: $sidebar-bg;
    background-color: $tabs-bg;

    &:hover {
      box-shadow: $btn-box-shadow;
    }
  }
}

.add-item-popup-container.voluum-popup-container {
  .form-wrap {
    .without-config-item-block {
      margin-bottom: 0;
      padding-bottom: 1rem;
    }

    label {
      text-transform: initial;
    }

    .custom-datalist {
      width: 70%;
      height: 100%;
      margin-top: 0.25rem;
      border: 1px solid #F0F2F5;
      border-radius: .5rem;
      @include font-regular;
      font-size: .875rem;
      line-height: 1rem;
      color: $logout-font;
    }

    legend {
      margin-top: 1rem;
    }

    .input-range-block {
      @include flexbox-start-center;
      padding-bottom: 1rem;

      label {
        margin: 0;
      }

      input {
        width: 6.5rem;
        margin: 0 1rem;
      }
    }

    .input-range-inner-block {
      position: relative;

      .form-error {
        position: absolute;
        bottom: -2rem;
        left: 0;
        width: 90%;
        font-size: .75rem;
        line-height: 1;
      }
    }
  }
}

.form-submit-block {
  @include flexbox-start-center;
  padding: 0 2rem;
}

.report-form-wrap.form-wrap {
  height: auto;
  overflow: inherit;

  .blue-btn {
    align-self: flex-start;
    margin-top: 1rem;
    &:disabled {
      background-color: gray;
      border-color: gray;
      cursor: not-allowed;
    }
  }

  .form-flex-container {
    padding: 0 0 1rem;
  }

  .input-wrap,
  .input-block {
    margin: 0;

    &.error-input input {
      border-color: $error;
    }
  }

  form {
    input,
    select,
    textarea,
    .category-dropdown {
      width: 100%;
      min-width: 5rem;
      height: 3rem;
      margin: 0;
      padding: .75rem 1.25rem;
      border: 1px solid $tabs-bg;
    }

    .category-dropdown {
      padding: .8rem 2.5rem .8rem 1.25rem;
    }

    label {
      margin-bottom: .35rem;
      @include font-medium;
      font-size: .875rem;
      line-height: 1rem;
      color: $sidebar-bg;
    }
    .category-dropdown {
      &.editable-dropdown {
        padding: 0.8rem .5rem 0.8rem;
        input {
          padding: 0.5rem;
          height: 2rem;
        }
        ul {
          top: 45px;
          left: -1px;
          width: calc(100% + 2px);
        }
        li {
          position: relative;
          &:hover {
            background: $main-bg;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          .delete-icon {
            position: absolute;
            top: 9px;
            right: 10px;
            background-color: transparent;
            border: none;
            outline: none;
          }
        }
        .table-loader {
          top: 12px;
          right: 11px;
        }
        .delete-error {
          position: absolute;
          top: 8px;
          right: 19px;
          color: #ff6b72;
          font-size: 20px;
          &-text {
            top: 22px;
            font-size: 10px;
          }
        }
      }
    }
  }

  .flex-range-block {
    position: relative;
    @include flexbox-start;
    @include flex-direction-column;
    margin-right: 1rem;

    .react-datepicker-wrapper input {
      min-width: 14rem;
    }

    .category-dropdown {
      min-width: 135px;
      z-index: 2;
    }
  }

  .error-text {
    margin-top: 0;
    position: absolute;
    bottom: -2.5rem;
    left: 0;
  }

  .react-datepicker__close-icon {
    right: 6px;
  }
}
