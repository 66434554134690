@import "../../../styles/mixin";

.error-popup {
  position: absolute;
  right: 10px;
  padding: 10px;
  background-color: #FFFFFF;
  background-clip: border-box;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  transition: all 0.3s ease;
  opacity: 0;
  top: 0;
  z-index: -1;

  span {
    @include font-regular;
    font-size: 16px;
    color: #f44335;
  }

  &.popup-active {
    z-index: 2;
    opacity: 1;
    top: 20%;
  }
}