@import "variables";
@import "breakpoints";
@import "mixin";

.sidebar-title {
  text-align: center;
  @include font-bold;
  font-size: 1.25rem;
  line-height: 1.375;
  color: $sidebar-font;
}

.title {
  @include font-regular;
  font-size: 1rem;
  line-height: 1.625;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
}
