@import '../../styles/mixin';

.voluum-text {
  margin: -.5rem 0 1.15rem;
  font-size: 1.15rem;
}

.voluum-config-table-no-data {
  position: absolute;
  top: 7.5rem;
}
