@import "../../styles/variables";
@import "../../styles/mixin";

.campaigns-container {
  .row-select-single-selected {
    background-color: #ffffff !important;
    font-weight: normal !important;
    border-bottom: 1px solid #ffffff !important;
  }

  .row-select-clickable {
    cursor: default !important;
  }

  .dt-buttons-container {
    display: flex;
    align-items: center;
    margin: 0 0 10px;

    .blue-btn {
      margin: 0 15px 0 0;
      min-height: 30px;
      padding: 0 1.5rem;
      display: flex;
    }
  }
}


.data-table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;

  .pagination-wrap {
    margin: 0 0 0 15px;
  }
}

.data-table-rows-limit {
  max-width: 150px;
  width: 100%;
}

.data-table-rows-count {
  font-size: 14px;
  margin: 0 0 0 auto;
}

.campaign-change-status-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .3rem .7rem .35rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.02857em;
  text-align: center;
  border-radius: 0.5rem;
  outline: 0;
  border: 0;
  background: #467FF7;
  color: #FFFFFF;
  transition: all 150ms ease-in 0s;
  cursor: pointer;

  &.activate-btn {
    background: #3d9135;
  }
}