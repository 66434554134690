@import "../../styles/variables";

.reports-download-block {
  position: absolute;
  top: 6rem;
  right: 2rem;
}

.reports-title {
   margin: -3rem 0 1rem;
   width: 100%;
   text-align: right;
   padding-right: 1rem;
   font-size: 1rem;
   color: $sidebar-bg;
}
