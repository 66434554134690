@import "../../styles/mixin";
@import "../../styles/variables";

.doubled-checkbox-block {
  @include flexbox-start-center;
  margin-bottom: .5rem;
  p {
    margin-right: 1rem;
    font-size: 1rem;
    color: $sidebar-bg;
  }
  input {
    position: relative;
    display: inline-block;
    width: 7rem;
    height: 2rem;
    padding: 0;
    vertical-align: top;
    background-color: #8698b2;
    border: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: $transition;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      left: .2rem;
      top: .17rem;
      width: 1.65rem;
      height: 1.65rem;
      border-radius: 50%;
      background-color: $popup-bg;
      transform: translateX(0);
      transition: $transition;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0.45rem;
      right: 0.65rem;
      @include font-medium;
      color: $sidebar-font;
      font-size: 14px;
      transform: translateX(0);
      transition: $transition;
    }
    &.top-all {
      &::before {
        content: "All offers";
      }
    }

    &:checked {
      background-color: $sidebar-bg;

      &::after {
        transform: translateX(300%);
      }
      &::before {
        left: 0.65rem;
        right: auto;
      }
      &.top-all {
        &::before {
          content: "Top offers";
        }
      }
    }

    &.disabled,
    &:disabled {
      cursor: not-allowed;
      &:before {
        color: #666666;
      }
      &:checked {
        border: 1px solid #999999;
        background-color: $disabled-bg;
      }
    }
  }
}
