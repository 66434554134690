@import "../../../styles/variables";
@import "../../../styles/mixin";

.info-popup-overlay {
  position: absolute;
  top: 80px;
  right: -17px;
  bottom: 0;
  left: -17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(48, 54, 75, 0.5) 0%, rgba(36, 41, 57, 0.5) 100%);
    box-shadow: 0 4px 24px rgba(150, 150, 178, 0.1);
    z-index: 2;
  }
}

.info-popup {
  position: relative;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 0 24px rgba(150, 150, 178, 0.1);
  border-radius: 6px;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;

}

.info-popup-confirm-btn {
  display: block;
  width: 47%;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75rem 0;
  @include font-medium;
  font-size: .875rem;
  line-height: 1rem;
  border: none;
  border-radius: $btn-border-radius;
  color: $sidebar-font;
  transition: $transition;
  cursor: pointer;
}

.info-popup-confirm-btn {
  background-color: $btn-bg;

  &:hover {
    box-shadow: $btn-box-shadow;
  }
}

.info-popup-close-btn {
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 15px;
    height: 15px;
  }
}

.info-popup-text {
  margin: 0 0 10px;
  text-align: center;
}