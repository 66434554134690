@import "../../styles/variables";
@import "../../styles/mixin";

.offers-container {
  .row-select-single-selected {
    background-color: #ffffff !important;
    font-weight: normal !important;
    border-bottom: 1px solid #ffffff !important;
  }

  .row-select-clickable {
    cursor: default !important;
  }

  .dt-buttons-container {
    display: flex;
    align-items: center;
    margin: 0 0 10px;

    .blue-btn {
      margin: 0 15px 0 0;
      min-height: 30px;
      padding: 0 1.5rem;
      display: flex;
    }

    .item-delete-button {
      height: 30px;
      margin: 0 0 0 15px;
    }
  }
}


.data-table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;

  .pagination-wrap {
    margin: 0 0 0 15px;
  }
}

.data-table-rows-limit {
  max-width: 150px;
  width: 100%;
}

.data-table-rows-count {
  font-size: 14px;
  margin: 0 0 0 auto;
}