@import "../../../styles/mixin";

.loading-wrap {
  width: calc(100vw - 19rem);
  height: calc(100vh - 3rem);
  @include flexbox-center;
  text-align: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  margin-left: 1rem;

  div {
    position: absolute;
    top: 20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: loaderAnimation1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: loaderAnimation2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 24px;
      animation: loaderAnimation2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 42px;
      animation: loaderAnimation3 0.6s infinite;
    }
  }
}

@keyframes loaderAnimation1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loaderAnimation3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loaderAnimation2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(18px, 0);
  }
}
