@import "../../styles/variables";
@import "../../styles/mixin";

.custom-tooltip {
  padding: .375rem .9rem;
  background: $btn-bg;
  border-radius: .25rem;
  @include font-regular;
  font-size: .875rem;
  line-height: 1rem;
  color: $sidebar-font;
}