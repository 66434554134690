@import "../../../styles/variables";
@import "../../../styles/mixin";

.show-more-btn {
  @include flexbox-center;
  width: 2.25rem;
  height: 2.25rem;
  background: $table-bg;
  border: 1px solid $btn-bg;
  border-radius: $border-radius;
  transition: $transition;
  cursor: pointer;

  &:hover {
    background-color: $btn-bg;
  }
}
