@import "../../styles/mixin";
@import "../../styles/variables";

.category-dropdown {
  position: relative;
  min-width: 7rem;

  &:hover {
    background: #ffffffad;
  }

  .category-icon {
    position: absolute;
    width: 20px;
    top: 23%;
    right: .85rem;
    font-size: 1.75rem;
    transform: rotate(0);
    transition: $transition;
  }

  &.active {
    background: $sidebar-font;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: $btn-box-shadow !important;
    .category-icon {
      transform-origin: 50% 45%;
      transform: rotate(180deg);
    }
  }

  &.transparent-btn {
    padding: 0.625rem 2.5rem 0.625rem 1.5rem;
  }
}
.custom-select-option-container {
  position: absolute;
  left: 0;
  top: calc(2.5rem + 1%);
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  text-align: center;
  background-color: $sidebar-font;
  border: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: $btn-box-shadow;
  z-index: 10;
  &::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }

  &::-webkit-scrollbar-track {
    background: #d6d6d6;
  }

  &::-webkit-scrollbar-thumb {
    background: #696970;
    border-radius: $btn-border-radius;
    box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  }
}
.custom-select-option {
  padding: .5rem .25rem;
  width: 100%;

  &:last-child:hover {
    background: $main-bg;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
