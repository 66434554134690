@import '../../styles/variables';
@import '../../styles/mixin';
@import '../../styles/typography';
@import '../../styles/breakpoints';

.sidebar-container {
  position: relative;
  width: $sidebar-width;
  height: 100%;
  margin-right: 1rem;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.collapse-menu {
    .sidebar-inner-container {
      overflow-y: inherit;
      z-index: 5;
    }
    .pages-block-doc {
      margin-top: -2rem;
    }
    .page-title-block-doc {
      margin: -1rem 0;
    }
  }

  .sidebar-inner-container {
    position: fixed;
    height: inherit;
    width: inherit;
    padding: 6rem 0 1rem;
    color: $sidebar-font;
    background: $sidebar-bg;
    box-shadow: $sidebar-box-shadow;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: .5rem;
    }

    &::-webkit-scrollbar-track {
      background: #404047;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #696970;
      border-radius: $border-radius;
      box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    }
  }

  &.collapse-menu {
    width: 7rem;
    margin-right: 0;

    .page-title-block {
      @include flexbox-center;
      padding: 0;
      cursor: pointer;
    }

    .pages-block {
      margin: 2rem 0;

      &:first-child {
        margin-top: 0;
      }
    }

    .page-icon {
      margin-right: 0;
    }
  }
}

.home-title-block{
  @include flexbox-start-center;
  padding: 0 2.5rem .5rem;

  .page-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    padding: .25rem;
  }

  a {
    display: block;
    width: calc(100% - 3rem);
    margin: .5rem 0;
    @include font-bold;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.03333em;
    text-transform: uppercase;
    color: $sidebar-font;
  }
}

.page-title-block,
.page-title-block-li-item {
  @include flexbox-start-center;
  padding: 0 2.5rem .5rem;
}

.page-title-block.page-title-block-doc {
  .sidebar-popup li {
    padding: 0 0.5rem 0.5rem;
  }

  li {
    padding: 0;

    a {
      padding: 0.5rem;
      margin-bottom: -.5rem;
    }
  }
}

.pages-block-doc,
.pages-block {
  .sidebar-section-title {
    display: block;
    margin: 0.5rem 0;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: #8F9EB0;
  }
}

.pages-block {
  position: relative;

  h4 {
    display: block;
    margin: .5rem 0;
    @include font-bold;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.03333em;
    text-transform: uppercase;
    &.disabled {
      position: relative;
      display: inline;

      &:after {
        content: 'coming soon';
        position: absolute;
        top: -.25rem;
        right: -6.5rem;
        padding: .3rem .5rem;
        text-transform: capitalize;
        @include font-bold;
        font-size: .75rem;
        line-height: .875rem;
        color: $sidebar-font;
        background-color: $btn-bg;
        border-radius: .25rem;
      }
    }
  }

  ul {
    margin: 0 0 .5rem;
  }

  li {
    position: relative;
    margin: 0;
    padding: 0 2.5rem;
    background-color: transparent;
    transition: background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    &:hover {
      background-color: $active-btn;
    }

    &.disabled:hover {
      background-color: transparent;
    }

    &.active {
      cursor: pointer;
      background-color: $active-btn;
      transition: $transition;

      & a {
        color: $btn-bg;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: .25rem;
        background-color: $btn-bg;
      }

      &:hover {
        background-color: $active-btn;
      }
    }

    &.disabled p {
      position: relative;
      display: inline;

      &:after {
        content: 'coming soon';
        position: absolute;
        top: .15rem;
        right: -6.5rem;
        padding: .3rem .5rem;
        text-transform: capitalize;
        @include font-bold;
        font-size: .75rem;
        line-height: .875rem;
        color: $sidebar-font;
        background-color: $btn-bg;
        border-radius: .25rem;
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: .45rem 0 .45rem 1rem;
      @include font-medium;
      font-size: .8755rem;
      text-decoration: none;
      border-radius: $btn-border-radius;
      color: $sidebar-font;
      background-color: transparent;
      transition: $transition;
    }

    p {
      display: block;
      width: 100%;
      height: 100%;
      padding: .25rem 0 .25rem 1rem;
      @include font-thin;
      font-size: .875rem;
      text-decoration: none;
      border-radius: $btn-border-radius;
      color: $sidebar-font;
      background-color: transparent;
      transition: $transition;
    }
  }
}

.sidebar-link {
  padding-left: 0;
  font-weight: 500;
  font-style: normal;
  font-size: 0.8755rem;
  color: #FFFFFF;
  background-color: transparent;
  transition: $transition;
}

.page-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  padding: .25rem;

  &.page-icon-bg {
    background: $table-bg;
    border-radius: $btn-border-radius;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.logout-btn {
  width: calc(100% - 6rem);
  margin: 2rem 3rem 0;
  padding: .65rem .5rem;
  @include font-bold;
  font-size: .875rem;
  line-height: 1rem;
  cursor: pointer;
  border-radius: $btn-border-radius;
  border: 1px solid $logout-font;
  color: $logout-font;
  background-color: transparent;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &.collapse-menu {
    @include flexbox-center;
    width: 4rem;
    height: 2rem;
    margin: 3rem auto 1rem;
    padding: 0;
  }
}

.sidebar-popup {
  position: absolute;
  top: 1rem;
  left: 5.5rem;
  display: none;
  padding: .25rem;
  background: $table-bg;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  z-index: 5;

  li,
  li.disabled{
    width: 100%;
    padding: .5rem 3rem .5rem 1rem;
    border-radius: $border-radius;
    white-space: nowrap;

    a {
      padding: 0;
      color: $sidebar-bg;
    }

    &.active {
      background-color: transparent;
      cursor: default;

      &:hover {
        background-color: transparent;
      }

      &:after {
        content: none;
      }

      a {
        cursor: default;
      }
    }

    p {
      padding: 0 5rem 0 0;
      @include font-medium;
      font-size: .875rem;
      color: $sidebar-bg;

      &:after {
        top: -0.25rem;
        right: -2.5rem;
      }
    }
  }
}

.pages-block:hover .sidebar-popup {
  display: block;
}
