@import "../../styles/variables";
@import "../../styles/mixin";

.react-tabs__tab {
  bottom: 0;
}

.tabs-collapse-menu {
  .tab-list-container {
    max-width: calc(100vw - 9.5rem);
  }
}

.react-tabs__tab-list {
  display: flex;
  position: relative;
  width: fit-content;
  border-bottom: 1px solid $tabs-bg;
  margin-bottom: 1rem;
  overflow: hidden;
}

.tab-list-container {
  position: relative;
  @include flexbox-start;
  max-width: calc(100vw - $sidebar-width - 3rem);

  .react-tabs__tab-list li,
  .react-tabs-show-more-btn {
    margin: 0 .1rem;
    padding: 11px 20px;
    text-align: center;
    text-transform: capitalize;
    @include font-regular;
    font-size: .875rem;
    line-height: 1rem;
    color: $sidebar-bg;
    white-space: nowrap;
    background-color: $tabs-bg;
    border: 1px solid $tabs-bg;
    border-radius: 4px 4px 0 0;
    transition: $transition;
    cursor: pointer;

    &:last-child {
      min-width: 4rem;
    }

    &:hover {
      background-color: $table-bg;
    }

    &:focus-visible {
      outline: none;
    }

    &.hidden {
      display: none;
    }

    &.react-tabs__tab--selected {
      background: $table-bg;
      border-style: solid;
      border-color: $tabs-bg;
      border-radius: 4px 4px 0 0;
      color: $btn-bg;
      box-shadow: none;

      &:hover {
        background-color: $sidebar-font;
      }
    }
  }
}

.tabs-popup {
  position: absolute;
  top: 3rem;
  right: 1rem;
  padding: .25rem .1rem;
  background-color: $table-bg;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  z-index: 9;

  &.hidden {
    visibility: hidden;
  }

  div {
    padding: .75rem 3rem .75rem .9rem;
    @include font-regular;
    font-size: .875rem;
    line-height: 1rem;
    color: $sidebar-bg;
    cursor: pointer;
    
    &:hover {
      border-radius: $border-radius;
      background-color: rgba(70, 127, 247, 0.1);
    }
  }
}

.react-tabs__tab-list .react-tabs-show-more-btn {
  padding: 8px 20px 11px;
  @include font-bold;
  font-size: 1.25rem;
  line-height: 1rem;
}

.react-tabs__tab:focus:after {
  content: none;
}

.react-tabs__tab--selected {
  border-radius: $border-radius;
  box-shadow: rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.react-tabs__popup {
  position: absolute;
  @include flexbox-start;
  @include flex-direction-column;
  background-color: $popup-bg;
}
