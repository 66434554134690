@import "../../../styles/mixin";
@import "../../../styles/variables";

.form-wrap.textarea-checker-form-wrap {
  width: 100%;
  max-width: calc(100vw - $sidebar-width - 3rem);
  height: auto;

  &.collapsed-menu {
    max-width: calc(100vw - $sidebar-width + 8rem);
  }

  form {
    align-items: flex-start;

    label.drop-label {
      min-width: 21rem;
      padding: 2rem 1rem;;
      border-color: $btn-bg;
      transition: $transition;

      &:hover {
        border-color: $btn-bg-hover;

        svg path {
          fill: $btn-bg-hover;
        }
      }

      svg path {
        fill: $btn-bg;
      }
    }

    .file-name {
      margin: .5rem 0;
      font-size: .85rem;
    }

    .input-wrap {
      margin-bottom: 0;

      &.error-input {
        .input-block textarea {
          border-color: $error;
        }
      }
    }

    .input-block textarea {
      width: 100%;
      height: 100%;
      min-height: 8rem;
      border-color: #467ff757;
    }
  }
}
