@import "../../styles/variables";
@import "../../styles/mixin";

.pagination-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.1;
}

.pagination-btn {
  background: transparent;
  padding: 0;
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: .4;
    cursor: default;
  }

  img {
    height: 16px;
    display: block;
  }
}