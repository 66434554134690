@import "../../styles/variables";
@import "../../styles/mixin";

.MuiBox-root {
  padding: 1rem 0 0 !important;
}

.MuiTabs-scroller button {
  background: #F0F2F5 !important;
  color: $main-font !important;
}

.MuiTabs-indicator {
  background-color: $btn-bg !important;
}

.MuiSvgIcon-root {
  fill: $main-font !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 0.3 !important;
}