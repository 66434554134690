.message-sending-wrap {
  position: absolute;
  top: 80px;
  right: -17px;
  bottom: 0;
  left: -17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(48, 54, 75, 0.5) 0%, rgba(36, 41, 57, 0.5) 100%);
    box-shadow: 0 4px 24px rgba(150, 150, 178, 0.1);
    z-index: 2;
  }

  .message-sending-block {
    position: relative;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0 0 24px rgba(150, 150, 178, 0.1);
    border-radius: 6px;
    text-align: center;
    font-size: 14px;
    z-index: 2;

    button {
      position: absolute;
      top: -20px;
      right: -20px;
      padding: 0;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }
}