@import "../../../styles/variables";

.item-update-button {
  width: 2.25rem;
  height: 2.25rem;
  color: $sidebar-font;
  background: $btn-bg;
  border: none;
  border-radius: $border-radius;
  transition: $transition;
  cursor: pointer;

  &:hover {
    background-color: $btn-bg-hover;
  }
}
