@import 'styles/variables';
@import 'styles/mixin';
@import 'styles/typography';
@import 'styles/breakpoints';

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
}

html {
  position: relative;
  scroll-behavior: smooth;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  font-size: 16px;

  @include mobileBreakpoint {
    font-size: 14px;
  }
}

body {
  min-height: 100vh;
  margin: 0;
  @include font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem;
  line-height: 1.625;
  letter-spacing: 0.00938em;
  color: $main-font;
  background: $main-bg;
}

a {
  color: $main-font;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.main-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  margin: 0 auto;
}

.page-container {
  padding: 6rem 0 1rem 0;
  width: calc(100vw - $sidebar-width - 2rem);
  transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.collapsed-menu {
    width: 100%;
    margin-left: 3rem;
  }
}

.transparent-btn {
  min-height: 2.5rem;
  margin-bottom: 1rem;
  padding: .625rem 1.5rem;
  display: table;
  text-align: center;
  @include font-bold;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  outline: 0;
  border-radius: $btn-border-radius;
  border: none;
  opacity: 1;
  color: rgb(52, 71, 103);
  background: $popup-bg;
  z-index: 1;
  transition: all 150ms ease-in 0s;
  cursor: pointer;

  &:hover {
    box-shadow: $btn-box-shadow;
  }
}

.page-btns-container {
  @include flexbox-start-center;
  width: calc(100vw - $sidebar-width - 3rem);
  transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.collapsed-menu {
    width:  calc(100vw - $sidebar-width + 8rem);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.gradient-container {
  position: absolute;
  top: 4rem;
  width: calc(100vw - $sidebar-width + 5rem);
  height: 15.5rem;
  margin-left: -2rem;
  color: $sidebar-font;
  background: linear-gradient(111deg, #053D7E 13.89%, #20FAFC 86.15%);
  transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  z-index: -1;

  &.collapse-menu {
    width: calc(100vw  - $sidebar-width + 12rem);
  }

  h3 {
    margin-top: 4rem;
    width: 100%;
    text-align: center;
    @include font-bold;
    font-size: 2.5rem;
    z-index: 1;
  }
}

//Global loader styles//
.loader-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader {
    margin: 0;
  }
}
