@import "../../styles/variables";

.closed-campaigns-container {
  .react-datepicker__input-container input {
    max-width: 12rem;
    margin: 0 .75rem 1rem 0;
    padding-right: 1.25rem;
  }

  .react-datepicker__close-icon {
    height: auto;
    //margin: 0.75rem .15rem 0 0;
    margin: 0;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
}

.full-w {
  .react-datepicker__input-container input {
    min-width: auto;
    max-width: none;
    width: 100%;
  }
}
