@import "../../../styles/variables";
@import "../../../styles/mixin";

.data-table-wrap {
  .row-select-selected {
    background-color: rgba(70 127 247 / 60%)!important;
    border-bottom: 1px solid rgba(70 127 247 / 100%)!important;
  }

  .blue-btn {
    margin: 0;
  }
}

.data-table-cell {
  cursor: pointer;
}