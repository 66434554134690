@import "../../../styles/mixin";

.clear-filters-btn.transparent-btn {
  @include flexbox-space-between;
  margin: 0 .75rem 1rem .75rem;

  span {
    margin-left: .5rem;
    text-transform: capitalize;
    font-size: .875rem;
  }
}
