@import "../../styles/mixin";
@import "../../styles/variables";

.user-page {
  .user-page-container {
    h3 {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }

  .user-page-wrap {
    position: relative;
    width: calc(100vw - $sidebar-width - 2rem);
    min-height: auto;
    display: -moz-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 8rem;
    transition: $transition;

    &.collapse-menu {
      width: calc(100vw - $sidebar-width + 8rem);
    }
  }

  .wrap {
    padding: 47px 70px;
    max-width: 1181px;
    width: 90%;
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    box-shadow: 0 0 24px rgba(150, 150, 178, 0.1);
    border-radius: 16px;
    min-height: 270px;
  }

  .block-l,
  .block-r {
    width: 50%;
  }

  .user-data {
    li {
      @include font-regular;
      font-size: 14px;
      line-height: 1.14;
      color: $sidebar-bg;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        @include font-medium;
      }
    }
  }

  .reset-pass {
    @include font-bold;
    padding: 0.3rem 0.5rem;
    text-transform: capitalize;
    letter-spacing: 0.0075em;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #FFFFFF;
    background-color: #467FF7;
    border-radius: 0.25rem;
    border: none;
    margin-top: 20px;
  }

  .block-r {
    h3, p {
      margin-bottom: 16px;
    }

    h3 {
      @include font-bold;
      font-size: 24px;
      line-height: 1.16;
      color: $sidebar-bg;
    }

    p {
      @include font-regular;
      font-size: 14px;
      line-height: 1.16;
    }

    button {
      padding: 12px 20px;
      border: none;
      border-radius: 8px;
      @include font-medium;
      font-size: 14px;
      line-height: 1.16;

      &.setup-btn {
        background: #467FF7;
        color: #ffffff;
      }

      &.disable-btn {
        background: transparent;
        border: 1px solid #3E79F7;
        color: $btn-bg;
      }
    }
  }
}