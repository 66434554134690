@import "../../styles/mixin";
@import "../../styles/variables";

.alerts-download-select-container {
  @include flexbox-start-center;
  margin-bottom: 1rem;

  .transparent-btn {
    margin-bottom: 0;
  }

  .react-datepicker__input-container input {
    margin: 0 0.5rem 0 auto;
  }
}

.alerts-download-page {
  h3 {
    color: $sidebar-bg;
  }
}