@import "../../styles/mixin";
@import "../../styles/variables";

.closed-campaigns-offer-info {
  @include flexbox-start;
  @include flex-direction-column;
  margin: 0 0 1rem auto;
  @include font-bold;
  font-size: .875rem;
  color: $sidebar-bg;

  span {
    @include font-regular;
  }
}