@import '../../styles/variables';
@import '../../styles/mixin';
@import '../../styles/typography';
@import '../../styles/breakpoints';

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  @include flexbox-start-center;
  width: 100%;
  padding: .5rem 0;
  background-color: $header-bg;
  box-shadow: $box-shadow;
  z-index: 10;
}

.logo-container {
  @include flexbox-center;
  width: $sidebar-width;
  padding: 1rem 2.5rem;
  @include  font-bold;
  line-height: 1.75rem;
  color: $sidebar-bg;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.collapse-menu {
    width: 7rem;
    font-size: 1rem;

    img {
      width: 4.5rem;
    }
  }

  img {
    width: 7.375rem;
  }
}

.header-inner-container {
  @include flexbox-space-between;
  width: calc(100vw - $sidebar-width - 2rem);
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.collapse-menu {
    width: calc(100vw - 8.5rem);
  }
}

.title-container {
  @include flexbox-space-between;
}

.menu-icon {
  @include flexbox-center;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  transition: right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  cursor: pointer;

  &.collapse-menu {
    right: -4.75rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    margin-left: .5rem;
    color: $logout-font;
  }
}

.user-container {
  @include flexbox-space-between;
}

.user-inner-container {
  position: relative;
  @include flexbox-space-between;
}

.user-image {
  @include flexbox-center;
  width: 2.25rem;
  height: 2.25rem;
  margin-right: .5rem;
  border-radius: 100%;
  @include font-bold;
  line-height: 1rem;
  color: #1890FF;
  background: rgba(24, 144, 255, 0.2);
}

.user-name {
  margin-bottom: .2rem;
  @include font-bold;
  line-height: 1;
  font-size: .875rem;
  color: $main-font;
}

.user-project {
  @include font-regular;
  line-height: 1;
  font-size: .875rem;
  color: $logout-font;
}

.user-menu-button {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 2rem;
  transform: rotate(180deg);
  transition: all .3s;

  &.open {
    transform: rotate(0deg);
  }
}

.user-menu-popup {
  position: absolute;
  bottom: -7rem;
  right: -.5rem;
  width: 100%;
  min-width: 12rem;
  padding: .25rem;
  background-color: $header-bg;
  border-radius: $btn-border-radius;
  box-shadow: $box-shadow;


  .user-menu-popup-item {
    @include font-medium;
    font-size: .875rem;
    color: #3E79F7;
    transition: $transition;
    cursor: pointer;

    &:hover {
      background-color: #dad4d433;
    }

    a,
    span {
      display: block;
      width: 100%;
      padding: .75rem 1rem;
    }

    a {
      color: $sidebar-bg;
    }
  }
}

.pcom-logo {
  margin-left: 3rem;

  img {
    width: 2.5rem;
  }
}
