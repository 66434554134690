@import "../../styles/mixin";
@import "../../styles/variables";

.home-bg-container {
  p {
    width: 100%;
    text-align: center;
    @include font-regular;
    font-size: 1rem;
  }
}

.homepage-content-container {
  position: relative;
  display: block;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  margin: 8.5rem auto 0;
  padding: 1rem 2rem 2rem 5rem;

  &::-webkit-scrollbar {
    width: .5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ul {
    @include flexbox-center;
    align-items: stretch;
    @include flex-wrap(wrap);
    text-align: center;

    li {
      position: relative;
      width: calc(32% - 2rem);
      min-height: 17rem;
      margin: 0 1rem 1rem 0;
      background-color: $table-bg;
      box-shadow: $box-shadow;
      border-radius: $btn-border-radius;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        .img-block {
          background: rgba(255, 204, 0, 0.2);
        }
      }

      &:nth-child(even) {
        .img-block {
          background: rgba(4, 209, 130, 0.2);
        }
      }

      &:nth-child(4n+3) {
        .img-block {
          background: rgba(24, 144, 255, 0.2);
        }
      }

      &:nth-child(4n+4) {
        .img-block {
          background: rgba(255, 107, 114, 0.2);
        }
      }

      .img-block {
        @include flexbox-center;
        width: 5.375rem;
        height: 5.375rem;
        margin: 1.5rem auto;
        border-radius: .75rem;
      }

      h4 {
        margin: 1rem 0 0;
        @include font-bold;
        font-size: 1.5rem;
        line-height: 1.75rem;
        text-align: center;
        color: $sidebar-bg;
      }

      p {
        margin: 1rem 0 1.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        line-height: 1rem;
        text-align: center;
        color: $logout-font;
      }
    }
  }
}

.error-handler-container.gradient-container {
    position: relative;
    top: 0;
    width: 80vw;
    height: inherit;
    margin: 8rem auto 0;
    padding: 3rem;

  div {
    text-align: center;
  }
}